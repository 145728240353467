<script setup lang="ts" generic="T extends string | number | boolean">
    interface FormCheckboxProps {
        forId: string;
        trueValue?: T;
        falseValue?: T;
        label: string;
        title?: string;
        description?: string;
        required?: boolean;
        hidden?: boolean;
        error?: string;
        checkedValue?: T;
        name?: string | number;
        disabled?: boolean;
        // TODO: discuss if noMargin should be default
        // I think margin is not part of the component, but is the job of the layout that uses the component.
        noMargin?: boolean;
    }

    const props = withDefaults(defineProps<FormCheckboxProps>(), {
        required: false,
        trueValue: () => 'yes' as T,
        falseValue: () => 'no' as T,
        title: '',
        description: '',
        error: '',
        name: '',
    });

    const emit = defineEmits(['update:checkedValue', 'update:consent']);

    const toggle = computed({
        get: () => {
            return props.checkedValue;
        },
        set: (newValue) => {
            emit('update:checkedValue', newValue);
            emit('update:consent', { name: props.name, value: newValue });
        },
    });
</script>

<template>
    <div :class="['mol-form-checkbox relative', { 'text-woom-red': !!props.error, hidden: props.hidden, 'mb-5': !props.noMargin }]">
        <div class="flex flex-col gap-3">
            <p
                v-if="props.title"
                v-html="woomTextFormat(props.title)"
                class="link-style"></p>
            <p
                v-if="props.description"
                v-html="woomTextFormat(props.description)"
                class="link-style"></p>
            <div class="relative ml-4 md:flex md:items-center">
                <input
                    :id="props.forId"
                    v-model="toggle"
                    :true-value="props.trueValue"
                    :false-value="props.falseValue"
                    type="checkbox"
                    :name="props.forId"
                    :aria-describedby="props.error ? `${props.forId}-help` : undefined"
                    :required="props.required"
                    :disabled="props.disabled"
                    :value="props.trueValue"
                    :class="[
                        'peer relative size-[1.125rem] shrink-0 appearance-none rounded-sm border-2 border-woom-black bg-transparent accent-woom-black outline-none ring-offset-2 transition-all before:absolute before:left-1/2 before:top-1/2 before:block before:size-10 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:opacity-0 before:transition-opacity checked:bg-woom-black hover:before:bg-woom-black hover:before:opacity-[.04] focus:ring-2 focus:ring-woom-black checked:focus:ring-transparent checked:focus:before:bg-woom-black checked:focus:before:opacity-[.12] active:focus:ring-transparent disabled:opacity-30 before:disabled:opacity-0',
                    ]" />

                <span class="ml-4">
                    <label
                        :for="props.forId"
                        class="link-style"
                        v-html="woomTextFormat(props.label)">
                    </label>
                    <lazy-atm-form-required v-if="props.required" />
                </span>

                <!-- TODO: Replace polyline with actual SVG icon from woom CDN -->
                <svg
                    class="pointer-events-none absolute hidden size-[1.125rem] stroke-white stroke-3 peer-checked:block max-md:top-0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </div>
        </div>
        <lazy-atm-form-error
            v-if="props.error"
            :error="props.error"
            :for-id="props.forId" />
    </div>
</template>
